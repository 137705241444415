import { Button } from "primereact/button"
import { Checkbox } from "primereact/checkbox"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import FormationService from "../../../../services/formationService"
import { FileUploader } from "../../../fileUploader/fileUploader"
import { CommuneSelector } from "../../../location-selector/communeSelector"
import DefaultAvatar from "../../../../images/profilEtudiant/DefaultAvatar.webp"
import CV from "../../../../images/profilEtudiant/CV.webp"
import Portfolio from "../../../../images/profilEtudiant/Portfolio.webp"
import './personnalInformation.css'
import allowedImageFileType from "../../../../valueContol/allowedImageFileType.json"
import allowedCVFileType from "../../../../valueContol/allowedCVFileType.json"
import allowedPortfolioFileType from "../../../../valueContol/allowedPortfolioFileType.json"
import { enumDocumentCategorie } from "../../../../enums/enumDocumentCategorie"
import { Wysiwyg } from "../../../wysiwyg/wysiwyg"
import { FormNavigationButtons } from "../../formNavigationButtons"
import AuthService from '../../../../services/authService';
import { ActivateAccountDialog } from '../../../../scenes/activateAccountDialog'
import { RadioButton } from "primereact/radiobutton";
import { InputMask } from 'primereact/inputmask';
import { enumConfWysiwyg } from "../../../../enums/enumConfWysiwyg"
import { ProgressSpinner } from "primereact/progressspinner"

export const PersonnalInformation = (props) => {

    const { t } = useTranslation();
    const [isStranger, setIsStranger] = useState(props.etudiant?.etranger)
    const [showDialogActivation, setShowDialogActivation] = useState(false);
    const [frequenceReception, setFrequenceReception] = useState([]);
    const formationService = new FormationService();
    const authService = new AuthService();

    useEffect(() => {
        loadData()
        return () => {
            // cleanup
            setFrequenceReception([])
        }
    }, [])

    const loadData = () => {
        formationService.getFrequenceReception()
            .then((_frequence) => {
                setFrequenceReception(_frequence);
            });
    }

    const handleEtrangerChange = (e, name) => {
        props.setFormIsDirty(true);
        let _etudiant = props.etudiant;

        let val = (e.checked) || false;
        _etudiant[`${name}`] = val;

        _etudiant.lieuResidence = null;
        _etudiant.pays = null;
        _etudiant.commune = null;
        _etudiant.communeId = null;

        setIsStranger(val);
        props.setEtudiant(_etudiant);
    };

    const onFileChange = () => {
        props.setFormIsDirty(true);
    }

    const askValidationEmail = () => {
        setShowDialogActivation(true);
        authService.askValidationEmail(props.etudiant?.email)
            .then(async response => {
            })
            .catch(error => {
                props.toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.try_again') });
            });
    }


    return (
        props.loadingContent ? (
            <div className="dip-ta-center">
                <ProgressSpinner />
            </div>
        ) : (
        <div>
            <ActivateAccountDialog show={showDialogActivation} email={props.etudiant?.email} closeEvent={() => setShowDialogActivation(false)}></ActivateAccountDialog>
            <div className="p-fluid p-formgrid p-grid pif-global-input-container">
                {/* firstname */}
                <div className="pif-input-container p-field p-col-12 p-sm-12 p-lg-4">
                    <label htmlFor='prenom'>
                        <Trans i18nKey='general.firstname' />*
                    </label>
                    <InputText 
                        id='prenom'
                        value={props.etudiant?.prenom}
                        onChange={(e) => props.onInputChange(e, 'prenom')}
                        required
                        maxLength="80"
                        className={classNames({ 'p-invalid': props.submitted && !props.etudiant?.prenom })} 
                    />
                    {props.submitted && !props.etudiant?.prenom && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                </div>
                {/* lastname */}
                <div className="pif-input-container p-field p-col-12 p-sm-12 p-lg-4">
                    <label htmlFor='nom'>
                        <Trans i18nKey='general.lastname' />*
                    </label>
                    <InputText id='nom'
                        value={props.etudiant?.nom}
                        onChange={(e) => props.onInputChange(e, 'nom')}
                        required
                        maxLength="80"
                        className={classNames({ 'p-invalid': props.submitted && !props.etudiant?.nom })}
                    />
                    {props.submitted && !props.etudiant?.nom && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                </div>
                {/* city */}
                <div className="pif-input-container p-field p-col-12 p-sm-12 p-lg-4">
                    <label htmlFor='commune'>
                        <Trans i18nKey='general.residence_location' />*
                    </label>
                    {!isStranger && <CommuneSelector value={props.etudiant?.commune?.id ? (props.etudiant?.commune) : ('')} onChange={(e) => props.onInputChange(e, 'commune')} />}
                    {isStranger && <InputText id='lieuResidence'
                        value={props.etudiant?.lieuResidence}
                        onChange={(e) => props.onInputChange(e, 'lieuResidence')}
                        required
                        className={classNames({ 'p-invalid': props.submitted && !props.etudiant?.lieuResidence })} />}
                    {isStranger && props.submitted && !props.etudiant?.lieuResidence && props.etudiant?.pays && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                    <div className="p-field-checkbox p-mt-2">
                        <Checkbox inputId="etranger" checked={isStranger} onChange={e => handleEtrangerChange(e, 'etranger')} />
                        <label htmlFor="etranger"><Trans i18nKey="profile.living_abroad" /></label>
                    </div>
                </div>
                {/* phone number */}
                <div className="pif-input-container p-field p-col-12 p-sm-12 p-lg-4">
                    <label htmlFor='phoneNumber'>
                        <Trans i18nKey='general.phone_number' />
                    </label>
                    <InputMask
                        id="phoneNumber" 
                        mask="99 99 99 99 99"
                        slotChar=""
                        placeholder="Ex: 0123456789"
                        value={props.etudiant?.phoneNumber}
                        onChange={(e) => props.onInputChange(e, 'phoneNumber')}
                    ></InputMask>
                </div>
                {/* email */}
                <div className="pif-input-container p-field p-col-12 p-sm-12 p-lg-4">
                    <label htmlFor='email'>
                        <Trans i18nKey='login.email' />*
                    </label>
                    <InputText id='email'
                        value={props.etudiant?.email}
                        onChange={(e) => props.onInputChange(e, 'email')}
                        disabled={props.etudiant?.id != null}
                        maxLength="255"
                        className={classNames({ 'p-invalid': props.submitted && !props.etudiant?.email })}
                    />
                    {props.submitted && !props.etudiant?.email && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                    {props.etudiant?.isDisabled && <small className="p-error"><Trans i18nKey="register.message_account_activation_email" /></small>}
                </div>
                {/* country */}
                <div className="pif-input-container p-col-12 p-sm-12 p-lg-4">
                    {isStranger && 
                        <div className="p-field"><label htmlFor="pays"><Trans i18nKey="campus.country" />*</label>
                            <InputText id="pays" name="pays"
                                value={props.etudiant.pays}
                                onChange={(e) => props.onInputChange(e, 'pays')}
                                className={classNames({ 'p-invalid': props.submitted && !props.etudiant.pays })}
                            />
                            {props.submitted && !props.etudiant?.pays && props.etudiant?.lieuResidence && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
                        </div>
                    }
                </div>
                {/* button activation */}
                <div className="p-field p-col-12 p-sm-12 p-lg-12">
                    {props.etudiant?.isDisabled && <div className="send-email-validation-btn"><Button label={t('register.receive_account_activation_email')} className="frs-button" onClick={askValidationEmail} /></div>}
                </div>
            </div>
            <div className="form-row">
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col-12 p-sm-12 p-lg-4">
                        <div className="personnal-info-file-container">
                            <FileUploader
                                className="personnal-info-file-container"
                                fileName="photo"
                                documentName="fichierPhoto"
                                entity={props.etudiant}
                                setEntity={props.setEtudiant}
                                onChange={onFileChange}
                                maxFileSize={10000000}
                                flavorPicture={DefaultAvatar}
                                tooltip={t('profile.square_maxMo', { size: 10000000 / 1000000 })}
                                hasPreview={true}
                                accept=".png,.jpg,.jpeg"
                                allowedType={allowedImageFileType}
                                documentCategory={enumDocumentCategorie.PHOTOETUDIANT}
                            />
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-4">
                        <div className="personnal-info-file-container">
                            <FileUploader
                                className="personnal-info-file-container"
                                fileName="cvPdf"
                                documentName="fichierCvPdf"
                                entity={props.etudiant}
                                setEntity={props.setEtudiant}
                                onChange={onFileChange}
                                maxFileSize={10000000}
                                flavorPicture={CV}
                                tooltip={t('profile.pdf_maxMo', { size: 10000000 / 1000000 })}
                                hasPreview={false}
                                accept=".pdf"
                                allowedType={allowedCVFileType}
                                documentCategory={enumDocumentCategorie.CVPDFETUDIANT}
                                buttonSetLabel = {t('profile.add_my_cv')}
                            />
                        </div>
                    </div>
                    <div className="p-field p-col-12 p-sm-12 p-lg-4">
                        <div className="personnal-info-file-container">
                            <FileUploader
                                className="personnal-info-file-container"
                                fileName="portfolio"
                                documentName="fichierPortfolio"
                                entity={props.etudiant}
                                setEntity={props.setEtudiant}
                                onChange={onFileChange}
                                maxFileSize={10000000}
                                flavorPicture={Portfolio}
                                tooltip={t('profile.pdf_maxMo', { size: 10000000 / 1000000 })}
                                hasPreview={false}
                                accept=".pdf"
                                allowedType={allowedPortfolioFileType}
                                documentCategory={enumDocumentCategorie.PORTFOLIO}
                            />
                        </div>
                    </div>
                    {/* wysiwyg */}
                    <div className="p-field p-col-12 p-sm-12 p-lg-12 pif-wysiwyg-container">
                        <h2 className="personnal-info-subtitle"><Trans i18nKey="profile.presentation_with_detail" /></h2>
                        <Wysiwyg 
                            entityType={'etudiant'} 
                            entityId={props.etudiant?.id} 
                            value={props.etudiant?.presentation} 
                            onChange={props.onEditorChange} 
                            Conf={enumConfWysiwyg.BASIC}
                        ></Wysiwyg>
                    </div>
                </div>
            </div>
            {/* receiving frequency */}
            <div className="p-fluid p-formgrid p-grid">
                <span className="p-col-12 personnal-info-subtitle"><Trans i18nKey='profile.notification_by_email' /></span>
                {
                    frequenceReception?.map((frequence) => {
                        return (
                            <div key={frequence} className="p-col-12 p-sm-12 p-lg-3">
                                <div className="p-field-radiobutton">
                                    <RadioButton 
                                        inputId={frequence} 
                                        className="pif-radio-button"
                                        name="frequenceReception" 
                                        value={props.etudiant?.frequenceReception?.toString()} 
                                        onChange={(e) => props.onInputEtudiantRBChange(frequence, 'frequenceReception')} 
                                        checked={props.etudiant?.frequenceReception === frequence} 
                                    />
                                    <label htmlFor={frequence}>{frequence}</label>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <FormNavigationButtons
                firstStep
                isVisualizationAllowed={props.etudiant?.id}
                previousStep={props.previousStep}
                nextStep={props.nextStep}
                onSave={props.onSave}
                setOnSave={props.setOnSave}
                isSubmitting={props.isSubmitting} 
            />
        </div >
        )
    )
}